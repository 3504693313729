import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'

import eventBus from './event_bus'
import { HashRouter } from 'react-router-dom';

React.icons = icons

const renderReactDom = () => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter >
        <App />
      </HashRouter>
    </Provider>,
    document.getElementById('root')
  );
};

let FirebasePlugin;

// Init
function onDeviceReady() {
  FirebasePlugin = window.FirebasePlugin;

  //Register handlers
  FirebasePlugin.onMessageReceived(function (message) {
    try {
      console.log("onMessageReceived");
      console.dir(message);
      if (message.messageType === "notification") {
        if (message.tap) {
          window.localStorage.setItem('plant', message.plant);

          const redirect_to = (message.page?(message.page === 'sparepart'?"/sparepart/management":"/transaction/out"):'');

          setTimeout(() => {
            eventBus.dispatch('navigate', { to: redirect_to })
          }, 500);
        } else {
          handleNotificationMessage(message);
        }
      }
    } catch (e) {
      console.error("Exception in onMessageReceived callback: " + e.message);
    }
  }, function (error) {
    console.error("Failed receiving FirebasePlugin message", error);
  });

  /*FirebasePlugin.onTokenRefresh(function (token) {
    console.log("Token refreshed: " + token)
    window.localStorage.setItem('device_token', token);
  }, function (error) {
    console.error("Failed to refresh token", error);
  });

  FirebasePlugin.registerAuthStateChangeListener(function (userSignedIn) {
    console.log("Auth state changed: User signed " + (userSignedIn ? "in" : "out"));
  });*/

  if (window.localStorage.getItem('device_token') === null) {
    checkNotificationPermission(false); // Check permission then get token
  }
}

// Notifications
var checkNotificationPermission = function (requested) {
  FirebasePlugin.hasPermission(function (hasPermission) {
    if (hasPermission) {
      console.log("Remote notifications permission granted");
      // Granted
      getToken();
    } else if (!requested) {
      // Request permission
      console.log("Requesting remote notifications permission");
      FirebasePlugin.grantPermission(checkNotificationPermission.bind(this, true));
    } else {
      // Denied
      console.error("Notifications won't be shown as permission is denied");
    }
  });
};

var getToken = function () {
  FirebasePlugin.getToken(function (token) {
    window.localStorage.setItem('device_token', token);
    console.log("Got FCM token: " + token);
  }, function (error) {
    console.error("Failed to get FCM token", error);
  });
};

var handleNotificationMessage = function (message) {
  var title;
  if (message.title) {
    title = message.title;
  } else if (message.notification && message.notification.title) {
    title = message.notification.title;
  } else if (message.aps && message.aps.alert && message.aps.alert.title) {
    title = message.aps.alert.title;
  }

  var body;
  if (message.body) {
    body = message.body;
  } else if (message.notification && message.notification.body) {
    body = message.notification.body;
  } else if (message.aps && message.aps.alert && message.aps.alert.body) {
    body = message.aps.alert.body;
  }

  window.cordova.plugins.notification.local.schedule({
    title: title,
    text: body,
    foreground: true
  });
  console.log(title.body);

  window.cordova.plugins.notification.local.on("click", function (notification) {
    const redirect_to = (message.page?(message.page === 'sparepart'?"/sparepart/management":"/transaction/out"):'');
    eventBus.dispatch('navigate', { to: redirect_to })
  });
};

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
    onDeviceReady();
  }, false);

  const dtLaunched = new Date();

  let totalBackButtonClicked = 0;
  let lastBackButtonClicked = dtLaunched.getMilliseconds();

  document.addEventListener('backbutton', function (event) {
    if (document.referrer === "") {
      const dt = new Date();

      const currentMillis = dt.getMilliseconds();

      if ((currentMillis - lastBackButtonClicked) < 500) {
        totalBackButtonClicked++;
      } else {
        totalBackButtonClicked = 0;
      }

      if (totalBackButtonClicked === 3) {
        navigator.app.exitApp();

        totalBackButtonClicked = 0;
      } else {
        lastBackButtonClicked = currentMillis;

        if (totalBackButtonClicked === 2) {
          window.plugins.toast.showWithOptions(
            {
              message: "Press again to exit.",
              duration: "short", // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
              position: "bottom",
              addPixelsY: -40  // added a negative value to move it up a bit (default 0)
            }
          );
        } else {
          window.history.back(-1);
        }
      }
    }
  }, false);
} else {
  renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
