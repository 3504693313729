import React, { Component } from 'react';
import { HashRouter, Route, Switch, withRouter } from 'react-router-dom';

import CacheBuster from './CacheBuster';

import LanguageContext from './utils/context/LanguageContext'

//import axios from 'axios'

import { /*getData, isLogin, getLoginData,*/ storeData } from './utils/common'

import './scss/style.scss';

import eventBus from './event_bus'

const processing = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/site/login/Login'));
const SwitchLogin = React.lazy(() => import('./views/site/login/SwitchLogin'));
const AutoLogin = React.lazy(() => import('./views/site/login/AutoLogin'));
const Register = React.lazy(() => import('./views/site/register/Register'));
const Confirmation = React.lazy(() => import('./views/site/register/Confirmation'));
const ResendConfirmation = React.lazy(() => import('./views/site/register/ResendConfirmation'));
const PasswordRequestReset = React.lazy(() => import('./views/site/login/PasswordRequestReset'));
const PasswordReset = React.lazy(() => import('./views/site/login/PasswordReset'));
const Page401 = React.lazy(() => import('./views/site/page401/Page401'));
const Page404 = React.lazy(() => import('./views/site/page404/Page404'));
const Page500 = React.lazy(() => import('./views/site/page500/Page500'));
const PrintTransaction = React.lazy(() => import('./views/transaction/PrintTransaction'));
const PrintReportTransactionOut = React.lazy(() => import('./views/report/transaction/PrintReportTransactionOut'));

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'id',
      setLanguage: this.setLanguage
    }
  }

  setLanguage = language => {
    storeData('lang', language, (60 * 24 * 60));
    this.setState({ language: language.toLowerCase() });
  };

  componentDidMount() {
    /*if (getData('lang') === false) {
      storeData('lang', 'ID', (60 * 24 * 60));
    }*/
    storeData('lang', 'EN', (60 * 24 * 60));

    /*if (isLogin()) {
      this.updateToken();
    }*/

    this.setState({
      language: 'en'
    });

    eventBus.on('navigate', (data) => {
      this.props.history.push(data.to)
    })
  }

  componentWillUnmount() {
    eventBus.remove('navigate', this.handleNavigate)
  }

  /*updateToken = () => {
    console.log('preparing update token after refresh', window.localStorage.getItem('device_token'));
    let data = new URLSearchParams();
    data.append('email', getLoginData().profile.email);
    data.append('token', window.localStorage.getItem('device_token'));

    axios.post(process.env.REACT_APP_API_URL+'/user/token?lang='+getData('lang'), data, {
      auth: {
        username: process.env.REACT_APP_API_CLIENT_ID, password: process.env.REACT_APP_API_CLIENT_SECRET
      }
    }).then(response => {
      if (response.status === 200) {
        if (response.data.status === 'success') {
          storeData('device_token', window.localStorage.getItem('device_token'), (60*24*90));
          window.localStorage.removeItem('device_token');
        }
      }
    }).catch(error => {
      console.error(error);
    });
  }*/

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <LanguageContext.Provider value={this.state}>
              <HashRouter>
                <React.Suspense fallback={processing}>
                  <Switch>
                    <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                    <Route exact path="/switch" name="Switch Login" render={props => <SwitchLogin {...props} />} />
                    <Route exact path="/auto" name="Auto Login" render={props => <AutoLogin {...props} />} />
                    <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                    <Route exact path="/confirm/resend" name="Resend Confirmation" render={props => <ResendConfirmation {...props} />} />
                    <Route exact path="/confirm/:id" name="Confirmation Page" render={props => <Confirmation {...props} />} />
                    <Route exact path="/password/reset/request" name="Request Reset Password Page" render={props => <PasswordRequestReset {...props} />} />
                    <Route exact path="/password/reset" name="Reset Password Page" render={props => <PasswordReset {...props} />} />
                    <Route exact path="/401" name="Page 401" render={props => <Page401 {...props} />} />
                    <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                    <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                    <Route path="/transaction/:type/:id/print" name="Print Transaction" render={props => <PrintTransaction {...props} />} />
                    <Route path="/report/print/transaction/out" exact name="Print Report Transaction Out" render={props => <PrintReportTransactionOut {...props} />} />
                    <Route path="/" name="Home" lang={this.state.language} render={props => <TheLayout {...props} />} />
                  </Switch>
                </React.Suspense>
              </HashRouter>
            </LanguageContext.Provider>
          );
        }}
      </CacheBuster>
    );
  }
}

export default withRouter(App);
