import axios from 'axios'

const TOKEN_KEY = 'jwt';

function validateNumber(num) {
  return !!(num || num === 0) && typeof num === 'number' && !Number.isNaN(Number(num));
};

function validateString() {
  var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return !!(typeof str === 'string' && str);
};

function isRequired() {
  var param = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'param';
  throw new Error("".concat(param, " is required"));
};

function invalidType() {
  var param = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'param';
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'type';
  throw new Error("".concat(param, " must be a valid ").concat(type));
};

function minutesToMiliseconds() {
  var minutes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 5;
  var parseMinutes = !validateNumber(minutes) || minutes < 1 ? 5 : minutes;
  return parseMinutes * 60000;
};

function getItem() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : isRequired('name localstorage');
  if (!validateString(name)) invalidType('name', 'string');
  var itemStr = window.localStorage.getItem(name);

  if (!itemStr) {
    return null;
  }

  var item = JSON.parse(itemStr);

  if (item.expiry < new Date().getTime()) {
    window.localStorage.removeItem(name);
    return null;
  }

  return item.value;
};

function setItem() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : isRequired('name for window.localStorage');
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : isRequired('value for window.localStorage');
  var expiry = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 5;
  if (!validateString(name)) invalidType('name', 'string');
  var validExpiry = !validateNumber(expiry) ? 60000 : minutesToMiliseconds(expiry);
  var item = {
    value: value,
    expiry: new Date().getTime() + validExpiry
  };
  window.localStorage.setItem(name, JSON.stringify(item));
};

export function formatDecimal(num) {
    const values = num.toString().split('.');

    return (parseFloat(values[1]) > 0?parseFloat(num):parseFloat(num).toFixed(0));
}
    
//get subdomain
export const getSubdomain = () => {
    if (window.cordova) {
        if (getData('subdomain')) {
            return getData('subdomain');
        }

        return 'member';
    } else {
        return process.env.REACT_APP_SUBDOMAIN;
    }
}

//store data to local storage
export const storeData = (key, value, expiry = 5) => {
    setItem(key, value, expiry);
}

//remove local storage data
export const removeData = (key) => {
    window.localStorage.removeItem(key);
}

//return local storage data
export const getData = (key) => {
    if (getItem(key)) {
        return getItem(key);
    }

    return false;
}

//store login user data
export const login = (theData, expiry = 1440) => {
    setItem(TOKEN_KEY, theData, expiry);
}

//refresh token
export const refreshToken = () => {
    console.log('refreshing');
    let loginData = getLoginData();

    if (loginData) {
        axios.post(process.env.REACT_APP_API_URL+'/auth/token', {
            grant_type: 'refresh_token',
            refresh_token: loginData.refresh_token
        }, {
            headers: {
            Authorization: 'Bearer '+loginData.access_token
            }
        }).then(response => {
            console.log('refresh-token', response)
            if (response.data.status === 'success') {
                loginData['profile'] = response.data.data;

                storeData('jwt', JSON.stringify(loginData), (60*24*90));
            }
        }).catch(error => {
            removeData('jwt');
            window.location.href = '//'+process.env.REACT_APP_DOMAIN+'/#/login';
        });
    }
}

//remove login user data
export const logout = () => {
    //window.localStorage.removeItem('device_token');
    //window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.clear();
}

//check whether user is logged or not
export const isLogin = () => {
    if (getItem(TOKEN_KEY)) {
        return true;
    }

    return false;
}

//return login user data
export const getLoginData = () => {
    if (getItem(TOKEN_KEY)) {
        return JSON.parse(getItem(TOKEN_KEY));
    }

    return false;
}

//update account
export const updateAccount = () => {
    let loginData = getLoginData();

    if (loginData) {
        axios.get(process.env.REACT_APP_API_URL+'/account/data', {
            headers: {
            Authorization: 'Bearer '+loginData.access_token
            }
        }).then(response => {
            if (response.data.status === 'success') {
                storeData('account', JSON.stringify(response.data.data), (60*24*90));
            }
        });
    }
}

//check permissions
export const getAccountData = () => {
    const accountData = getData('account');

    if (accountData) {
        return JSON.parse(accountData);
    }

    return false;
}
//update user profile
export const updateProfile = () => {
    let loginData = getLoginData();

    if (loginData) {
        axios.get(process.env.REACT_APP_API_URL+'/user/profile', {
            headers: {
            Authorization: 'Bearer '+loginData.access_token
            }
        }).then(response => {
            if (response.data.status === 'success') {
                loginData['profile'] = response.data.data;

                storeData('jwt', JSON.stringify(loginData), (60*24*90));
            }
        });
    }
}

//check permissions
export const checkPermissions = (perms) => {
    const loginData = getLoginData();

    if (loginData) {
        const permissions = loginData.profile.role.permissions;

        if (permissions) {
            if (permissions.filter(perm => perms.includes(perm.name)).length > 0) {
                return true;
            }
        }
    }

    return false;
}